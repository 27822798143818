import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBanner from "../components/smallBanner"
import InfoComponent from "../components/infoComponent"
import NearbyStops from "../components/nearbyStops"
import MapOfArea from "../components/mapOfArea"

const Location = () => {
		const AcfQuery = useStaticQuery(
			graphql`
			  query LocationQuery {
				allWordpressPage(filter: {title: {eq: "Lokalizacja"}}) {
					edges {
						node {
							acf {
								banner_title_first_part
								banner_title_second_part
								banner_image {
									localFile {
										url
									}
								}
								info__title
								info_image {
									localFile {
										url
									}
								}
								info_content
								info_bottom_left
								info_bottom_right
								stops_image {
									localFile {
										url
									}
								}
								stops_repeater {
									image {
										localFile {
											url
										}
									}
									title
									content
								}
								map_title
								map_legend {
									image {
										localFile {
											url
										}
									}
									title
									content
								}
							}
						}
					}
				}
			  }
			`
		)
	
		const data = AcfQuery.allWordpressPage.edges[0].node.acf;

		return (
			<Layout>
				<SEO title="Lokalizacja" />
				<SmallBanner 
					image={ data.banner_image.localFile.url } 
					// image={ require('../assets/img/LocationBanner.jpg') }
					titleFirstPart={ data.banner_title_first_part  }
					titleSecondPart={ data.banner_title_second_part  }
				/>
				<InfoComponent 
					imageSide="right" 
					image={ data.info_image.localFile.url  }
					// image={ require('../assets/img/LocationInfo.jpg') }
					title={ data.info__title  }
					sideContent={ data.info_content }
					aditionalFirstColumn={ data.info_bottom_left }
					aditionalSecondColumn={ data.info_bottom_right }
				/>
				<NearbyStops 
					image={ data.stops_image.localFile.url }
					// image={ require('../assets/img/LocationInfo2.jpg') }
					stops_repeater={ data.stops_repeater }
				/>
				<MapOfArea 
					title={ data.map_title }
					legend={ data.map_legend }
				/>
			</Layout>
		)
}

export default Location

import React from "react"

const NearbyStops = ({ image, stops_repeater }) => (
	<section className="nearby-stops">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <img 
                        className="nearby-stops__image"
                        src={ image } 
                        alt=""
                    />
                </div>
                <div className="col-12 col-lg-6 nearby-stops__content-holder">
                    <div className="nearby-stops__content">
                        {
                            stops_repeater.map((item, index) => {
                            //     let icon = '';
                            // if (index === 0) {
                            //     icon = require('../assets/img/LocationIcon1.png');
                            // } else if (index === 1) {
                            //     icon = require('../assets/img/LocationIcon2.png');
                            // } else if (index === 2) {
                            //     icon = require('../assets/img/LocationIcon3.png');
                            // } else if (index === 3) {
                            //     icon = require('../assets/img/LocationIcon4.png');
                            // }
                            return <div className="nearby-stops__item" key={ index }>
                                    <div className="item__icon-holder">
                                        <img 
                                            src={ item.image.localFile.url } 
                                            alt="" 
                                            className="item__icon"
                                        />
                                        {/* <img 
                                            src={ icon } 
                                            alt="" 
                                            className="item__icon"
                                        /> */}
                                    </div>
                                    <div className="item__content">
                                        <h4>
                                            { item.title }
                                        </h4>
                                        <p className="item__info" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default NearbyStops

import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const FullWidthMap = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBH8wRfXWH7M6F7Xo3otDYHsCcaV4ns4fw&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `350px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(() => {

    const mapOptions = {
        styles: [
          {
              "featureType": "administrative",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#444444"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [
                  {
                      "color": "#f2f2f2"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": -100
                  },
                  {
                      "lightness": 45
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "all",
              "stylers": [
                  {
                      "color": "#cfcfcf"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          }
        ]
      };

    return (
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{ lat: 52.2426059, lng: 21.1075985 }}
            defaultOptions={{ styles: mapOptions.styles }}
        >
            <div className="marker__holder">
                <Marker
                    icon={{
                        url: require('../assets/img/pin.png'),
                        scaledSize:  new window.google.maps.Size(59,85)
                    }}
                    position={{ lat: 52.2426059, lng: 21.1075985 }}
                >
                </Marker>
                <Marker
                    icon={{
                        url: require('../assets/img/pin1.png'),
                        scaledSize:  new window.google.maps.Size(35,48)
                    }}
                    position={{ lat: 52.2421079, lng: 21.1116699 }}
                >
                </Marker>
                <Marker
                    icon={{
                        url: require('../assets/img/pin4.png'),
                        scaledSize:  new window.google.maps.Size(35,48)
                    }}
                    position={{ lat: 52.243123, lng: 21.1024597 }}
                >
                </Marker>
                <Marker
                    icon={{
                        url: require('../assets/img/pin2.png'),
                        scaledSize:  new window.google.maps.Size(35,48)
                    }}
                    position={{ lat: 52.240873, lng: 21.1014892 }}
                >
                </Marker>
                <Marker
                    icon={{
                        url: require('../assets/img/pin2.png'),
                        scaledSize:  new window.google.maps.Size(35,48)
                    }}
                    position={{ lat: 52.2316243, lng: 21.1060408 }}
                >
                </Marker>
                <Marker
                    icon={{
                        url: require('../assets/img/pin2.png'),
                        scaledSize:  new window.google.maps.Size(35,48)
                    }}
                    position={{ lat: 52.2441194, lng: 21.0863696 }}
                >
                </Marker>
                <Marker
                    icon={{
                        url: require('../assets/img/pin5.png'),
                        scaledSize:  new window.google.maps.Size(35,48)
                    }}
                    position={{ lat: 52.2427493, lng: 21.112335 }}
                >
                </Marker>
                <Marker
                    icon={{
                        url: require('../assets/img/pin3.png'),
                        scaledSize:  new window.google.maps.Size(35,48)
                    }}
                    position={{ lat: 52.243941, lng: 21.1131802 }}
                >
                </Marker>
            </div>
        </GoogleMap>
    )
})

export default FullWidthMap

import React from "react"
import FullWidthMap from "./FullWidthMap"

const MapOfArea = ({ title, legend }) => (
    <section className="map-of-area">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="map-of-area__title title-underline mb-60">
                        { title }
                    </h2>
                </div>
            </div>
        </div>
        <div className="map-of-area__map">
            <FullWidthMap />
        </div>
        <div className="container map-of-area__legend">
            <div className="row">
                {
                    legend.map((item, index) => {
                        // let icon = require('../assets/img/pin1.png');
                        // if(index === 2 || index === 3 || index === 4) {
                        //     icon = require('../assets/img/pin2.png');
                        // } else if (index === 1 ) {
                        //     icon = require('../assets/img/pin4.png');
                        // } else if (index === 5) {
                        //     icon = require('../assets/img/pin5.png'); 
                        // } else if (index === 6) {
                        //     icon = require('../assets/img/pin3.png');
                        // }
                        return <div className="col-12 col-md-6 col-lg-4" key={ index }>
                            <div className="legend__item">
                                <img 
                                    src={ item.image.localFile.url } 
                                    alt=""
                                    className="item__icon"
                                />
                                {/* <img 
                                    src={ icon }
                                    alt=""
                                    className="item__icon"
                                /> */}
                                <div className="item__content">
                                    <h3 className="mb-20" dangerouslySetInnerHTML={{ __html: item.title }}></h3>
                                    <p className="item__text" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </section>
)

export default MapOfArea
